import React from "react"
import { Spring } from "react-spring/renderprops"
import { StaticImage } from "gatsby-plugin-image"

import VisibilitySensor from "../visibility-sensor"
import { slideInSpringProps } from "../../services/helpers"
import Card from "../card"
import useWindowDimensions from "../../hooks/useWindowDimensions.js"

const StaticImageGlasses = () => (
  <StaticImage
    height={140}
    placeholder="blurred"
    src="../../images/business_how-it-works_glasses.png"
    alt=""
  />
)

const StaticImageChair = () => (
  <StaticImage
    height={140}
    placeholder="blurred"
    src="../../images/business_how-it-works_chair.png"
    alt=""
  />
)

const StaticImagePalm = () => (
  <StaticImage
    height={140}
    placeholder="blurred"
    src="../../images/business_how-it-works_palm.png"
    alt=""
  />
)

const boxes = [
  {
    title: "Prêt pour plus de clients",
    isQuestion: true,
    paragraphe:
      "Nos utilisateurs engagés viennent déposer leurs objets à recycler dans votre boutique.",
    image: <StaticImageGlasses />,
  },
  {
    title: "On s'occupe de tout",
    paragraphe:
      "Nous définissons ensemble les volumes de stockage et les moments de collectes.",
    image: <StaticImageChair />,
  },
  {
    title: "Flexible et sans engagement",
    paragraphe:
      "Vous arretez la collecte quand vous le souhaitez. Activez ou désactivez le service en un clic.",
    image: <StaticImagePalm />,
  },
]

const HowItWorks = ({ className }) => {
  const { isMobile } = useWindowDimensions()
  return (
    <section className={className}>
      <div style={{ overflow: "hidden" }} className="inner">
        <h3 className="text-center h2">
          <em className="variant">Simple</em> et efficace
        </h3>

        <div className="grid cols-3">
          {boxes.map(({ title, paragraphe, image, isQuestion }, index) => (
            <VisibilitySensor key={index} once partialVisibility>
              {({ isVisible }) => (
                <Spring
                  {...slideInSpringProps(
                    isVisible,
                    300,
                    index === 1 || !isMobile
                  )}
                >
                  {props => (
                    <Card className="reveal" style={{ ...props }}>
                      <div>{image}</div>
                      <h4 className="h5">
                        {title}
                        {isQuestion && <span>&nbsp;?</span>}
                      </h4>
                      <p>{paragraphe}</p>
                    </Card>
                  )}
                </Spring>
              )}
            </VisibilitySensor>
          ))}
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
