import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled from "@emotion/styled"
import { Spring } from "react-spring/renderprops"
import theme from "../../styles/theme"
import VisibilitySensor from "../visibility-sensor"

const images = [
  [
    [
      { title: "Bars & cafés", image: "imageBar" },
      { title: "Retails", image: "imageRetail" },
    ],
    [
      { title: "Collectivités", image: "imageMunicipality" },
      { title: "Bureautique", image: "imageOfficeshop" },
    ],
  ],
  [
    [
      { title: "Concept store", image: "imageConceptShop" },
      { title: "E-cigarette", image: "imageEcigarette" },
    ],
    [
      { title: "Sport", image: "imageSport" },
      { title: "Librairie", image: "imageBookshop" },
    ],
  ],
]

const ForWhom = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      imageBar: file(name: { eq: "business-for-whom_bar" }) {
        cloudinary: childCloudinaryAsset {
          fluid(maxWidth: 200, transformations: ["ar_4:6", "c_fill"]) {
            ...CloudinaryAssetFluid
          }
        }
      }
      imageRetail: file(name: { eq: "business-for-whom_retail" }) {
        cloudinary: childCloudinaryAsset {
          fluid(maxWidth: 200, transformations: ["ar_12:9", "c_fill"]) {
            ...CloudinaryAssetFluid
          }
        }
      }
      imageOfficeshop: file(name: { eq: "business-for-whom_officeshop" }) {
        cloudinary: childCloudinaryAsset {
          fluid(maxWidth: 200, transformations: ["ar_4:6", "c_fill"]) {
            ...CloudinaryAssetFluid
          }
        }
      }
      imageEcigarette: file(name: { eq: "business-for-whom_ecigarette" }) {
        cloudinary: childCloudinaryAsset {
          fluid(maxWidth: 200, transformations: ["ar_12:9", "c_fill"]) {
            ...CloudinaryAssetFluid
          }
        }
      }
      imageMunicipality: file(name: { eq: "business-for-whom_municipality" }) {
        cloudinary: childCloudinaryAsset {
          fluid(maxWidth: 200, transformations: ["ar_12:9", "c_fill"]) {
            ...CloudinaryAssetFluid
          }
        }
      }
      imageBookshop: file(name: { eq: "business-for-whom_bookshop" }) {
        cloudinary: childCloudinaryAsset {
          fluid(maxWidth: 200, transformations: ["ar_4:6", "c_fill"]) {
            ...CloudinaryAssetFluid
          }
        }
      }
      imageConceptShop: file(name: { eq: "business-for-whom_conceptstore" }) {
        cloudinary: childCloudinaryAsset {
          fluid(maxWidth: 200, transformations: ["ar_4:6", "c_fill"]) {
            ...CloudinaryAssetFluid
          }
        }
      }
      imageSport: file(name: { eq: "business-for-whom_sport" }) {
        cloudinary: childCloudinaryAsset {
          fluid(maxWidth: 200, transformations: ["ar_12:9", "c_fill"]) {
            ...CloudinaryAssetFluid
          }
        }
      }
    }
  `)

  return (
    <section className={className}>
      <div className="inner">
        <h3 className="text-center dark h2">
          Une solution <em className="variant2">ouverte à tous</em>
        </h3>
        <StyledImageContainer className="grid">
          {images.map((block, index) => (
            <StyledImageBlockContainer key={index} className="grid">
              {block.map((column, index) => (
                <div key={index}>
                  {column.map(({ image, title }, index) => (
                    <VisibilitySensor key={index} once partialVisibility>
                      {({ isVisible }) => (
                        <Spring
                          config={{ duration: 1500 }}
                          to={{
                            opacity: isVisible ? 1 : 0,
                          }}
                        >
                          {props => (
                            <div className="reveal" style={{ ...props }}>
                              <StyledImage
                                loading="auto"
                                fluid={data[`${image}`].cloudinary.fluid}
                                alt=""
                              />
                              <StyledShopName className="text-center">
                                {title}
                              </StyledShopName>
                            </div>
                          )}
                        </Spring>
                      )}
                    </VisibilitySensor>
                  ))}
                </div>
              ))}
            </StyledImageBlockContainer>
          ))}
        </StyledImageContainer>
      </div>
    </section>
  )
}

const StyledImageContainer = styled.div`
  @media only screen and (min-width: ${theme.breakpoint.small}px) {
    padding: 0 3rem;
  }
  @media only screen and (min-width: ${theme.layout.mediumPageWidth}px) {
    padding: 0 6rem;
  }
`

const StyledShopName = styled.h4`
  font-size: 1em;
  color: ${theme.palette.common.black};
  font-weight: ${theme.typography.fontWeight.bold};
  text-transform: none;
  margin: 0.5rem 0;
`

const StyledImageBlockContainer = styled.div`
  display: grid;
`

const StyledImage = styled(Image)`
  border-radius: ${theme.shape.borderRadius[0]};
  box-shadow: ${theme.elevation.level2};
`

export default ForWhom
