import React, { useState, useCallback } from "react"
import { ErrorMessage } from "../common"
import { debounce } from "../../services/helpers"
import {
  email,
  requiredCity,
  requiredShop,
  requiredNumber,
  requiredEmail,
  invalidEmail,
} from "../../services/validator"
import {
  message,
  formError,
  serverError,
  formSuccess,
} from "../../services/toast"
import Button from "../button";

const defaultValues = {
  city: "",
  shop: "",
  number: "",
  email: "",
  message: "",
}

const defaultTouched = {
  city: false,
  shop: false,
  number: false,
  email: false,
  message: false,
}

const Contact = () => {
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({ ...defaultValues })
  const [touched, setTouched] = useState({ ...defaultTouched })
  const [cityError, setCityError] = useState("")
  const [shopError, setShopError] = useState("")
  const [numberError, setNumberError] = useState("")
  const [emailError, setEmailError] = useState("")

  const resetForm = () => {
    setValues({ ...defaultValues })
    setTouched({ ...defaultTouched })
  }

  const isFormValid = () => {
    return (
      !cityError &&
      !shopError &&
      !numberError &&
      !emailError &&
      !!values.city &&
      !!values.shop &&
      !!values.number &&
      !!values.email
    )
  }

  const validateAll = () => {
    validate("city")
    validate("shop")
    validate("number")
    validate("email")
    setTouched({
      city: true,
      shop: true,
      number: true,
      email: true,
      message: false,
    })
  }

  const onChange = key => e => {
    setValues({
      ...values,
      [key]: e.target.value,
    })

    if (touched[key]) {
      debouncedValidate(key, e.target.value)
    }
  }

  const onBlur = key => e => {
    if (!touched[key]) {
      setTouched({
        ...touched,
        [key]: true,
      })
    }
    validate(key)
  }

  const validate = (key, passedValue) => {
    const value = passedValue !== undefined ? passedValue : values[key]
    switch (key) {
      case "city":
        setCityError(value ? "" : requiredCity)
        break
      case "shop":
        setShopError(value ? "" : requiredShop)
        break
      case "number":
        setNumberError(value ? "" : requiredNumber)
        break
      case "email":
        setEmailError(
          !value ? requiredEmail : email.test(value) ? "" : invalidEmail
        )
        break
      default:
        break
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValidate = useCallback(debounce(validate, 500), [])

  const onSubmit = async e => {
    setLoading(true)
    e.preventDefault()
    validateAll()
    if (isFormValid()) {
      try {
        const response = await fetch("/.netlify/functions/sendmail", {
          method: "POST",
          body: JSON.stringify(values),
        })
        if (response.status === 200) {
          message("success", formSuccess)
          resetForm()
        } else {
          message("error", serverError)
        }
      } catch (e) {
        message("error", serverError)
      } finally {
        setLoading(false)
      }
    } else {
      message("error", formError)
      setLoading(false)
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <input
        className={!!cityError ? "error" : ""}
        placeholder="Ma ville"
        type="text"
        value={values["city"]}
        onChange={onChange("city")}
        onBlur={onBlur("city")}
      />
      <ErrorMessage>{cityError}</ErrorMessage>
      <input
        className={!!shopError ? "error" : ""}
        placeholder="Nom de mon magasin"
        type="text"
        value={values["shop"]}
        onChange={onChange("shop")}
        onBlur={onBlur("shop")}
      />
      <ErrorMessage>{shopError}</ErrorMessage>
      <input
        className={!!numberError ? "error" : ""}
        placeholder="Numéro de portable"
        type="tel"
        value={values["number"]}
        onChange={onChange("number")}
        onBlur={onBlur("number")}
      />
      <ErrorMessage>{numberError}</ErrorMessage>
      <input
        className={!!emailError ? "error" : ""}
        placeholder="Adresse email"
        id="email"
        type="email"
        value={values["email"]}
        onChange={onChange("email")}
        onBlur={onBlur("email")}
      />
      <ErrorMessage>{emailError}</ErrorMessage>
      <textarea
        placeholder="Ecrivez-nous un petit mot &#128522;"
        id="message"
        value={values["message"]}
        onChange={onChange("message")}
        onBlur={onBlur("message")}
      />
      <ErrorMessage />
      <Button disabled={loading} className={'button--red'} type="submit">
        Envoyer
      </Button>
    </form>
  )
}

export default Contact
