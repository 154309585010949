import styled from "@emotion/styled"
import theme from "../styles/theme"

const StyledCard = styled.div`
  background-color: ${theme.palette.common.white};
  padding: 2rem 1rem;
  border-radius: ${theme.shape.borderRadius[1]};
  box-shadow: ${theme.elevation.level1};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
  h4 {
    margin-top: 1rem;
    min-height: 3rem;
  }

  h4,
  p {
    color: ${theme.palette.common.black};
    text-align: center;
  }

  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    & {
      margin-bottom: 0;
    }
  }
`

export default StyledCard
