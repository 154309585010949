import styled from "@emotion/styled"
import Button from "./button";

export default styled(Button)`
  &.visible {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation-delay: 0.5s;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`
