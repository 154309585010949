import React from "react"
import styled from "@emotion/styled"
import { Spring } from "react-spring/renderprops"
import { StaticImage } from "gatsby-plugin-image"

import { slideInSpringProps } from "../../services/helpers"
import theme from "../../styles/theme"
import VisibilitySensor from "../../components/visibility-sensor"
import useWindowDimensions from "../../hooks/useWindowDimensions.js"

const StaticImageBox = () => (
  <StaticImage
    height={140}
    placeholder="blurred"
    src="../../images/business_logistics_box.png"
    alt=""
  />
)

const StaticImageApp = () => (
  <StaticImage
    height={140}
    placeholder="blurred"
    src="../../images/business_logistics_app.png"
    alt=""
  />
)

const StaticImageCargo = () => (
  <StaticImage
    height={140}
    placeholder="blurred"
    src="../../images/business_logistics_cargo.png"
    alt=""
  />
)

const elements = [
  {
    image: <StaticImageBox />,
    paragraphe: "Une mise en place soignée et un emcombrement limité",
  },
  {
    image: <StaticImageApp />,
    paragraphe: "Chaque dépôt est tracé, PAS de stock important en boutique",
  },
  {
    image: <StaticImageCargo />,
    paragraphe: "Une collecte régulière et responsable en vélo cargo",
  },
]

const Logistics = ({ className }) => {
  const { isMobile } = useWindowDimensions()
  return (
    <section className={className}>
      <div className="inner">
        <h3 className="text-center h2 dark">
          <em>Pas de gestion</em> supplémentaire
        </h3>
        <div className="grid cols-3">
          {elements.map(({ image, paragraphe }, index) => (
            <VisibilitySensor key={index} once partialVisibility>
              {({ isVisible }) => (
                <Spring
                  {...slideInSpringProps(
                    isVisible,
                    300,
                    !(index === 1 || !isMobile)
                  )}
                >
                  {props => (
                    <StyledContainer className="reveal" style={{ ...props }}>
                      <StyledImageContainer>{image}</StyledImageContainer>
                      <p className="bold">{paragraphe}</p>
                    </StyledContainer>
                  )}
                </Spring>
              )}
            </VisibilitySensor>
          ))}
        </div>
      </div>
    </section>
  )
}

const StyledImageContainer = styled.div`
  background-color: ${theme.palette.common.green};
  box-shadow: ${theme.elevation.level2};
  width: calc(
    (
        ${theme.breakpoint.medium}px - 2 * ${theme.layout.gridGap} - 2 *
          ${theme.layout.pagePadding}
      ) / 3
  );
  height: calc(
    (
        ${theme.breakpoint.medium}px - 2 * ${theme.layout.gridGap} - 2 *
          ${theme.layout.pagePadding}
      ) / 3
  );
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: ${theme.palette.common.black};
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

export default Logistics
