import React, { useEffect, useRef } from "react"
import styled from "@emotion/styled"
import Typed from "typed.js"
import Contact from "./contact-form"

const options = {
  strings: ["durable", "solidaire", "eco-responsable"],
  typeSpeed: 40,
  backSpeed: 30,
  loop: false,
  showCursor: false,
  startDelay: 1000,
  backDelay: 700,
}

const HeroUnit = ({ className }) => {
  const typedRef = useRef()
  useEffect(() => {
    const typed = new Typed(typedRef.current, options)
    return () => {
      typed.destroy()
    }
  }, [])
  return (
    <section className={className}>
      <div className="inner grid grid-section grid_3-2">
        <h2 className="h1">
          <em>Augmentez vos ventes</em>
          <br />
          Avec un engagement
          <StyledTextContainer>
            <span ref={typedRef} />
            <span className="no-js ">eco-responsable</span>
          </StyledTextContainer>
        </h2>
        <div>
          <p className="dark text_small margin-bottom">
            Vous souhaitez capter de nouveaux clients&nbsp;? <br />
            Rejoignez notre réseau de commerçants engagés.
          </p>
          <Contact />
        </div>
      </div>
    </section>
  )
}

const StyledTextContainer = styled.span`
  display: block;
  min-height: 2.5em;
  height: 2.5em;
`

export default HeroUnit
