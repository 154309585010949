import React from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { animateScroll } from "react-scroll"

import theme from "../../styles/theme"
import VisibilitySensor from "../visibility-sensor"
import ShakingButton from "../shaking-button"
import shop from "../../assets/shop.svg"

const JoinUs = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(name: { eq: "business-join-us" }) {
        cloudinary: childCloudinaryAsset {
          fluid {
            ...CloudinaryAssetFluid
          }
        }
      }
    }
  `)
  const scrollToTop = () => {
    animateScroll.scrollToTop()
  }

  return (
    <section className={className}>
      <div className="grid grid-section inner">
        <StyledMainContent>
          <h3 className="h1 marginless">
            <em className="variant">Rejoignez</em>
            <br />
            le réseau WeRECY
          </h3>
          <p className="dark">
            Augmentez votre chiffre d'affaires avec l'économie circulaire
          </p>
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <StyledShakingButton
                onClick={scrollToTop}
                className={'button--blue' + (isVisible ? " visible" : "")}
              >
                Nous contacter
              </StyledShakingButton>
            )}
          </VisibilitySensor>
        </StyledMainContent>
        <StyledIllustrationContainer>
          <StyledShopImage src={shop} alt="" />
          <StyledImageContainer>
            <Image height="200px" fluid={data.image.cloudinary.fluid} alt="" />
          </StyledImageContainer>
        </StyledIllustrationContainer>
      </div>
    </section>
  )
}

const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
  padding-bottom: 2rem;
  gap: ${theme.layout.gridGap};
  
`

const StyledIllustrationContainer = styled.div`
  display: flex;
  align-items: flex-end;
  max-width: 400px;
  margin: auto;
  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    max-width: none;
    margin: 0;
  }
`

const StyledImageContainer = styled.div`
  min-width: 50%;
  position: relative;
  left: -70%;
`

const StyledShopImage = styled.img`
  transform: scale(1.3) translateY(-11%);
  position: relative;
  top: 0;
  left: 20%;
  bottom: 0;
  right: 0;
  min-width: 70%;
  width: auto; /* to keep proportions */
  height: auto; /* to keep proportions */
  margin: auto auto 0; /* position to bottom and center */
`

const StyledShakingButton = styled(ShakingButton)`
  max-width: 300px;
  margin-top: 2rem;
`

export default JoinUs
