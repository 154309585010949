import React from "react"
import styled from "@emotion/styled"
import theme from "../styles/theme"
import Layout from "../components/layout"
import ForWhom from "../components/business-page/for-whom"
import HeroUnit from "../components/business-page/hero-unit"
import HowItWorks from "../components/business-page/how-it-works"
import Logistics from "../components/business-page/logistics"
import JoinUs from "../components/business-page/join-us"
import Separator from "../assets/separator.inline.svg"

const Business = () => {
    return (
        <Layout>
            <StyledHeroUnit className="section-full"/>
            <HowItWorks/>
            <StyledSeparatorTop/>
            <ForWhom className="section-full bg-white"/>
            <StyledLogistics className=" bg-white"/>
            <StyledSeparatorBottom/>
            <JoinUs/>
        </Layout>
    )
}

const StyledSeparatorTop = styled(Separator)`
  height: 100px;
  transform: translateY(12px);
  display: none;
  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    display: block;
  }
`

const StyledSeparatorBottom = styled(Separator)`
  display: none;
  height: 100px;
  transform: rotate3d(1, 0, 0, 180deg) translateY(2px);
  @media only screen and (min-width: ${theme.breakpoint.medium}px) {
    display: block;
  }
`

const StyledHeroUnit = styled(HeroUnit)`
    padding-top: calc(2em + ${theme.layout.headerHeight});
    background: ${theme.palette.primary};
`

const StyledLogistics = styled(Logistics)``

export default Business
