import styled from "@emotion/styled"

import theme from "../styles/theme"

export const ErrorMessage = styled.small`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 1.5em;
  color: ${theme.palette.danger};
`
